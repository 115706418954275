<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :current-page="page"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :upload-file="true"
				@onBatchImport="uploadDialogShow"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
    <el-dialog :visible.sync="dialogVisible" title="批量导入">
      <div>
        <span style="display: inline-block;width: 180rem">请下载导入数据模板</span>
        <el-button size="small" @click="onDownload">点击下载模板</el-button>
      </div>
      <div style="margin: 24rem 0;display: flex;align-items: center">
        <span style="display: inline-block;width: 180rem">请上传导入文件</span>
        <el-upload :action="$_axios2.defaults.baseURL + '/api/teacher/account/import'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                   :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="file" style="width: 100rem">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
      <span style="color: red">{{ errorMessage }}</span>
    </el-dialog>
    <el-dialog :visible="rePasswordVisible" title="重置密码" width="430rem !important" custom-class="rePassword" @close="rePasswordVisible=false">
      <el-form :model="rePassword" :rules="rePasswordRule" ref="rePassword">
        <el-form-item label="设置密码" prop="password">
          <el-input v-model="rePassword.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="re_password">
          <el-input v-model="rePassword.re_password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
        <div slot="footer">
          <el-button @click="rePasswordVisible = false">取消</el-button>
          <el-button type="primary" @click="onRePassword">确定</el-button>
        </div>

    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true},default:true}},
  data() {
    return {
      loading:true,
      rePassword:{
        password:null,
        re_password:null,
        id:null
      },
      rePasswordRule:{
        re_password:[{required: true,message:"请再次输入密码"}],
        password:[{required: true,message:"请输入密码"}]
      },
      rePasswordVisible:false,
      searchConfig: [
        {
          prop: 'name',
          placeholder: '搜索姓名'
        },
        {
          prop: 'username',
          placeholder: '搜索账号'
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag:"select",
          label:"school_name",
          value:"id",
          options:[]
        },
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'username',
          label: '账号'
        },
        {
          prop: 'name',
          label: '姓名 '
        },
        {
          prop: 'paper',
          label: '所属校区'
        },
        {
          prop: 'roles',
          label: '岗位名称'
        },
        {
          prop: 'status',
          label: '账号状态',
          render(row){
            return row.status===1?'启用':'禁用'
          }
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          handle: true,
          width: 180,
          // buttons: row => {
          //   let btn = ['edit', 'enable'];
          //   if (row.status == 1) {
          //     btn = ['edit', 'disable']
          //   }
          //   return btn;
          // }
        }
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
      errorMessage: ''
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1);
    this.$utils.roleApi.getCampusList().then(res => {
      let { data } = res.data;
      this.$utils.updateOptions.apply(this, ['prop', 'school_id', data]);
    })
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this;
      this.loading = true;
      this.$_axios2.get('api/account/list', { params: { ...search, page } }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push('./add')
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, e,a,handle) {
      if (e.type === "edit") {
        this.$router.push({ path: './edit', query: { id: row.id } })
      } else if(e.type === "resetPassword"){
        this.rePasswordVisible = true;
        this.rePassword.id = row.id;
      }else {
        let msg = "启用后, 账号将恢复登录";
        if (e.type === "disable"){
          msg = "禁用后, 账号将不可登录"
        }
        let status = row.status == 1 ? 0 : 1;
        this.$confirm(msg, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$_axios2.get('api/teacher/account/status', { params: { id: row.id, status } }).then(res => {
            if (res.data.status === 0) {
              row.status = status;
              this.$message.success('操作成功');
              this.getData();
            }
          })
        })
      }
    },
    onRePassword(){
      this.$refs.rePassword.validate((res)=>{
        if (!res)return;
        this.rePasswordVisible = false;
        this.$_axios2.post("/api/account/reset-password",this.rePassword,{logic:1}).then(res => {
          this.$message.success("重置密码成功");
        })
      })
    },
    uploadDialogShow(){
      this.dialogVisible=true;
      this.errorMessage=''
    },
    onDownload() {
      location.href = 'https://main.pro.honghueducation.com:8003/bf-static/school-system/preview/teachers-accounts-import.xlsx '
    },
    onSuccess(res) {
      if (res.status === 0) {
        this.$message.success('上传成功')
        this.errorMessage = ''
      } else this.errorMessage = res.msg
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning'
        })
      }
      return extension || extension2
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }
  .pagination {
    margin-top: 50rem;
  }
}
.rePassword{
  .t_dialog .t_title_footer{
    margin: 0;
  }
}
</style>
